import { Chart, XAxis, YAxis, LineSeries } from "../../../../../src";
import { data1 as data } from "../../../../content/components/data";
import PropDef from "../../../../content/components/PropDef";
import * as React from 'react';
export default {
  Chart,
  XAxis,
  YAxis,
  LineSeries,
  data,
  PropDef,
  React
};